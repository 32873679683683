<template>
    <AnnotationImages
      :images="imageData"
      :project="this.projectId"
      @open-bottom-sheet="openBottomSheet"
    />
</template>
<script>
import { IOVThesholds } from '../../../../helpers/iovthresholds'
import AnnotationImages from './AnnotationImages.vue'
// import BottomSheet from './BottomSheet.vue'
import { mapState, mapActions } from 'vuex'
import { apiRoute } from './../../../../helpers/api-route'

export default {
  props: {
    projectId: null
  },
  components: {
    AnnotationImages
    // BottomSheet
  },

  computed: {
    ...mapState('labelledImagesSubmission', {
      images: (state) => state
    }),
    ...mapState('usersSubmission', ['members']),
    loadFetch () {
      return this.images.loading
    },
    readers () {
      if (this.members && this.members.length > 0 && this.projectId) {
        const _project = this.projectId._id
        const _readers = []
        this.members.forEach(_member => {
          const _role = _member.projectRole.find(_role => _role.project === _project)
          if (_role && _role.role === 'Reader') {
            _readers.push(_member)
          }
        })

        return _readers
      } else {
        return []
      }
    },
    moderators () {
      if (this.members && this.members.length > 0 && this.projectId) {
        const _project = this.projectId._id
        const _readers = []
        this.members.forEach(_member => {
          const _role = _member.projectRole.find(_role => _role.project === _project)
          if (_role && _role.role === 'Moderator') {
            _readers.push(_member)
          }
        })

        return _readers
      } else {
        return []
      }
    }
  },
  data () {
    return {
      selectedUser: null,
      selectedImage: null,
      bottomSheetOpen: false,
      imageData: [],
      filter: '',
      filterPath: '',
      search: ''
    }
  },

  methods: {
    ...mapActions('labelledImagesSubmission', ['fetchLabelledImages2']),
    searchImages (term) {
      this.search = term
      var searchFilter = this.images.labelledImages.filter(img => img.image.image.name.includes(term))
      this.imageData = this.transformImageData(searchFilter, false)
      // this.imageData = this.transformImageData(this.images.labelledImages, true, name)
    },
    filterImages (name) {
      // // console.log(name)
      if (this.search === '') {
        this.imageData = this.transformImageData(this.images.labelledImages, true, name)
      } else {
        var searchFilter = this.images.labelledImages.filter(img => img.image.image.name.includes(this.search))
        this.imageData = this.transformImageData(searchFilter, true, name)
      }
    },
    filterImagesPath (name) {
      // console.log(name)
      // if (this.search === '') {
      //   this.imageData = this.transformImageData(this.images.labelledImages, true, name)
      // } else {
      //   var searchFilter = this.images.labelledImages.filter(img => img.image.image.name.includes(this.search))
      //   this.imageData = this.transformImageData(searchFilter, true, name)
      // }
    },
    transformImageData (data, filterFlag, filtername) {
      if (!data) {
        return []
      }
      const transformedData = []
      if (!filterFlag || filtername === 'All' || filtername === '') {
        for (const item of data) {
          if (item.image && item.image.image && item.image.image.path && !item.image.removed.find(p => p.project === this.projectId._id)) {
            var acc
            if (item.image.accuracy.filter(a => a.project === this.projectId._id).length > 0) {
              acc = item.image.accuracy.filter(a => a.project === this.projectId._id).slice(-1)[0].value
            } else {
              acc = 0
            }
            var _acc = []
            if (item.otherImages && item.otherImages.length > 0 && item.image.image_set && item.image.image_set.name) {
              item.otherImages.forEach(_item => {
                const _accuracy = _item.image.accuracy.filter(a => a.project === this.projectId._id)
                if (_accuracy && _accuracy.length > 0) {
                  _acc.push(_item.image.accuracy.filter(a => a.project === this.projectId._id).slice(-1)[0].value)
                }
              })
            }
            // console.log('acc', acc)
            _acc.push(acc)
            // console.log('all acc', _acc)
            _acc = _acc.filter(a => a !== 0)
            if (_acc.length > 0) {
              // console.log('av  acc', _acc.reduce((a, b) => a + b) / _acc.length)
              acc = _acc.reduce((a, b) => a + b) / _acc.length
            } else {
              acc = 0
            }

            var _pathologies = []
            // console.log(item)
            item.pathologies.forEach(sub => {
              var keys = Object.keys(sub)
              keys.forEach(_k => {
                if (_k !== 'created_by') {
                  _pathologies.push(sub[_k].name)
                }
              })
            })
            if (item.otherImages && item.otherImages.length > 0 && item.image.image_set && item.image.image_set.name) {
              item.otherImages.forEach(_item => {
                _item.pathologies.forEach(sub => {
                  var keys = Object.keys(sub)
                  keys.forEach(_k => {
                    if (_k !== 'created_by') {
                      _pathologies.push(sub[_k].name)
                    }
                  })
                })
              })
            }
            var _tags = []
            // console.log(item)
            item.tags.forEach(sub => {
              var keys = Object.keys(sub)
              keys.forEach(_k => {
                if (_k !== 'created_by') {
                  _tags.push(sub[_k])
                }
              })
            })
            if (item.otherImages && item.otherImages.length > 0 && item.image.image_set && item.image.image_set.name) {
              item.otherImages.forEach(_item => {
                _item.tags.forEach(sub => {
                  var keys = Object.keys(sub)
                  keys.forEach(_k => {
                    if (_k !== 'created_by') {
                      _tags.push(sub[_k])
                    }
                  })
                })
              })
            }
            var _diagnoses = []
            console.log('dia confirm', item, item.image._id)
            console.log(item.image._id)
            item.diagnoses.forEach(sub => {
              var keys = Object.keys(sub)
              keys.forEach(_k => {
                if (_k !== 'created_by') {
                  // console.log('_k ', _k)
                  // console.log('_k ', sub[_k])
                  _diagnoses.push(sub[_k])
                }
              })
            })
            if (item.otherImages && item.otherImages.length > 0 && item.image.image_set && item.image.image_set.name) {
              item.otherImages.forEach(_item => {
                _item.diagnoses.forEach(sub => {
                  var keys = Object.keys(sub)
                  keys.forEach(_k => {
                    if (_k !== 'created_by') {
                      _diagnoses.push(sub[_k])
                    }
                  })
                })
              })
            }
            console.log('dia confirm2', _diagnoses, item.image._id)
            var modFlag = false
            var readerCount = 0
            item.users.forEach(us => {
              if (this.moderators.find(mod => mod._id === us._id)) {
                modFlag = true
              } else if (this.readers.find(red => red._id === us._id)) {
                readerCount++
              }
            })
            // console.log('_____________')
            // console.log(item.image._id)
            // console.log(modFlag)
            // console.log(readerCount)
            transformedData.push({
              id: item.image._id,
              url: apiRoute() + '/image/' + item.image._id,
              details: {
                created: item.image.created_at,
                format: item.image.image.contentType,
                imgName: item.image.image.name,
                meta: item.image.metadata,
                iou: acc,
                pathologies: new Set(_pathologies),
                tags: new Set(_tags),
                diagnoses: new Set(_diagnoses),
                image_set: item.image.image_set && item.image.image_set.name ? item.image.image_set : null,
                otherImages: item.otherImages
              },
              style: modFlag ? 'purple' : readerCount === this.readers.length && acc < IOVThesholds().fullAgreement ? 'green' : (readerCount === this.readers.length && acc >= IOVThesholds().partialAgreement ? 'red' : 'orange'),
              fullyAnnotated: readerCount === this.readers.length
            })
          }
        }
      }

      return transformedData
    },
    openBottomSheet (image) {
      try {
        this.$emit('open-bottom-sheet', image)
      } catch (error) {
        console.error('Error fetching labelled images:', error)
      }
    },

    closeBottomSheet () {
      this.bottomSheetOpen = false
    }
  },
  watch: {
    loadFetch (_new) {
      if (!_new) {
        this.imageData = this.transformImageData(this.images.labelledImages, false)
      }
    }
  },
  mounted () {
    this.imageData = this.transformImageData(this.images.labelledImages, false)
  }
}
</script>
