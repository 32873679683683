<template>
  <b-container fluid class="px-sm-5 position-relative index-1">
    <b-row class="no-gutters mt-4 pb-4">
      <b-col col md="5" class="align-self-center">
        <h1 class="color-white line-height-1">RATify | <small><small>AI ASSURANCE ORCHESTRATOR</small></small></h1>
        <span class="border-separator"></span>
        <h4 class="color-white mb-4">
          Be part of the movement to democratise healthcare.
        </h4>
        <div class="my-5 pt-1">
          <b-spinner scale="4" class="color-yellow"></b-spinner>
          <div class="text-white">Processing 3<sup>rd</sup> Party Login. Please, wait a second or two ...</div>
        </div>
        <p class="color-white">
          <strong class="color-white d-block mb-2">CONTACT US</strong>Should you
          have any feedback, comments, requests for technical support or other
          inquiries, please contact us by email:
          <a href="mailto:info@envisionit.africa">info@envisionit.africa</a>.
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  mounted () {
    this.proceed()
  },
  methods: {
    ...mapActions('alert', ['error']),
    ...mapActions('auth', ['loginResponse']),
    proceed: function () {
      const _params = this.$route.query

      if (_params && _params.code) {
        console.log(_params)
        this.loginResponse(_params)
      } else {
        this.error('Something went wrong with the authentication. Please, try again or contact support.')
      }
    }
  }
}
</script>
