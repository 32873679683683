<template>
  <Upload :projectId="this.project" @open-bottom-sheet="openBottomSheet"></Upload>
</template>

<script>
import Upload from '../Upload.vue'

export default {
  props: {
    project: null
  },
  methods: {
    openBottomSheet: function (image) {
      this.$emit('open-bottom-sheet', image)
    }
  },
  components: {
    Upload
  }
}
</script>
