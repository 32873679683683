<template>
  <div class="all-images mt-2">
    <div class="px-5 text-center pt-2">
      <b-row class="mb-4">
        <b-col md="6" v-if="!submitting">
          <b-button v-if="selectedImageCount > 0" class="ml-3 btn px-3 btn-sm btn-yellow rounded-pill" @click.prevent="submitToAI()">
            Submit {{ selectedImageCount }} image{{ selectedImageCount > 1 ? 's' : '' }} to AI
          </b-button>
          <p v-else-if="!allProcessed">No images selected, <a href="#" @click.prevent="selectAll()">select all?</a></p>
        </b-col>
        <b-col md="6" v-else>
            <b-spinner scale="4" class="color-yellow"></b-spinner>
            <div>Submitting Image/s...</div>
        </b-col>
        <b-col md="6">
          <!-- TODO: Add subgroup filters here -->
        </b-col>
      </b-row>
      <b-row align-h="center" class="pt-1">
                <b-input-group class="rounded-pill navy-fields">
                  <template #prepend>
                    <b-input-group-text class="rounded-tr-0 rounded-br-0 rounded-pill navy-fields form-control">
                      <font-awesome-icon icon="search" />
                    </b-input-group-text>
                  </template>
                  <template #append>
                    <b-input-group-text class="rounded-tl-0 rounded-bl-0 rounded-pill navy-fields form-control">
                    </b-input-group-text>
                  </template>
                  <b-form-input v-model="search" placeholder="Search Image Name" style="width: 25rem"></b-form-input>
                  <b-col>
                    <b-dropdown class="ml-3 btn px-3 btn-sm btn-yellow rounded-pill"
                      :text="filterChoice === '' ? 'All' : filterChoice" variant="warn">
                      <b-dropdown-item @click="filterChoice = 'All'">All</b-dropdown-item>
                      <b-dropdown-item v-for="sg in project.subgroups" :key="sg.name" @click="filterChoice = sg.name">{{
                        sg.name }}</b-dropdown-item>
                    </b-dropdown>
                      <p class="mt-0 pt-0 pl-3">Subgroups</p>
                  </b-col>
                  <!-- <b-col>
              <b-dropdown class="my-class ml-3 btn px-3 btn-sm btn-yellow rounded-pill"
                text="Pathology" variant="warn">
                  <b-checkbox class="mx-2" v-for="_p in pathList" :key="_p.label" v-model="filterChoicePathArray[_p.label]" @change="addPath(_p.label)">{{
                                _p.label }}</b-checkbox>
              </b-dropdown>
            </b-col> -->
                </b-input-group>
              </b-row>
      <b-row class="mb-4">
        <b-col md="3" class="mb-2 gx-1" v-for="image in paginatedData" :key="pageNumber + '_' + image.id"  cols="3">
          <div class="image-container">
            <b-col>
            <!-- :class="{ selected: image === selectedImage }" -->
            <input type="checkbox" v-model="selectedImages[image.id]" class="image-checkbox" :key="selectedImages[image.id]" :disabled="!(!imageAnalyzed(image) || (image.details.ai_diagnoses.has('Error') || image.details.ai_diagnoses.has('Unknown'))) " />
            <img class="img-fluid " :src="image.url + '?thumb=yes'"
            :class="image.details.image_set && image.details.image_set.name ? 'image-set' : ''"
            :style="'border-color: ' + image.styleFlag + ';'"
            @click.prevent="openBottomSheet(image)" />
            <div>
              <small class="image-name text-wite">{{ image.details.image_set && image.details.image_set.name ? image.details.image_set.name : image.details.imgName  }}</small>
            </div>
            <!-- Display the indicator with red or green color based on 'trained' property -->
            <small class="indicator" :class="imageAnalyzed(image) && !(image.details.ai_diagnoses.has('Error') || image.details.ai_diagnoses.has('Unknown')) ? 'trained-green' :
             (image.details.ai_diagnoses.has('Error') || image.details.ai_diagnoses.has('Unknown'))?
             'trained-red': 'trained-blue'">
              {{ imageAnalyzed(image) && !(image.details.ai_diagnoses.has('Error') || image.details.ai_diagnoses.has('Unknown')) ? "Analyzed"
              : imageAnalyzed(image) && (image.details.ai_diagnoses.has('Error')) ? 'Error'
              : imageAnalyzed(image) && (image.details.ai_diagnoses.has('Unknown')) ? 'Unknown':
              "Not yet submitted" }}
            </small>

            <small class="indicator-set trained-green" v-if="image.details.image_set && image.details.image_set.name">
                      {{ image.details.image_set.sequence }}
                    </small>
          </b-col>
          </div>
        </b-col>
      </b-row>

      <div class="text-center" v-if="paginatedData.length === 0">
        <!-- Show icon and message when images are empty -->
        <div class="empty-images mb-5">
          <i class="fa fa-image fa-3x"></i>
                <p>No images With Disagreement or Moderation.</p>
        </div>
      </div>

      <b-row align-h="center">
        <b-button class="ml-3 btn  btn-sm btn-yellow rounded-pill" @click="prevPage" :disabled="this.pageNumber===0">
          <i class="fa fa-chevron-left"></i>
        </b-button>
        <b-button v-if="pageNumber -3 > 0" class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(0)" :disabled="this.pageNumber===0">
          1
        </b-button>
        <p class="ml-3  mt-2"  v-if="pageNumber -3 > 0">...</p>
        <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill"  @click="setPage(pageNumber -2)" v-if="pageNumber-2 >= 0">
          {{ pageNumber -1  }}
        </b-button>
        <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill"  @click="setPage(pageNumber -1)" v-if="pageNumber-1 >= 0">
          {{ pageNumber }}
        </b-button>
        <b-button class="ml-3 btn btn-sm btn-blue rounded-pill" style="text-decoration: underline;">
          {{ pageNumber +1 }}
        </b-button>
        <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(pageNumber +1)" v-if="pageNumber +1 < maxPages">
          {{ pageNumber +2 }}
        </b-button>
        <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(pageNumber +2)" v-if="pageNumber +2 < maxPages">
          {{ pageNumber +3 }}
        </b-button>
        <p class="ml-3 mt-2" v-if="pageNumber +3 < maxPages">...</p>
        <b-button v-if="pageNumber +3 < maxPages" class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(maxPages -1)">
          {{maxPages}}
        </b-button>
        <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="nextPage" :disabled="this.pageNumber  +1  ===maxPages">
          <i class="fa fa-chevron-right"></i>
        </b-button>
        <b-dropdown :text="'' + itemsPerPage" class="ml-3 btn btn-sm btn-blue rounded-pill" variant="'primary'">
          <b-dropdown-item @click="setItemsPerPage(4)">4</b-dropdown-item>
          <b-dropdown-item @click="setItemsPerPage(8)">8</b-dropdown-item>
          <b-dropdown-item @click="setItemsPerPage(16)">16</b-dropdown-item>
          <b-dropdown-item @click="setItemsPerPage(32)">32</b-dropdown-item>
          <b-dropdown-item @click="setItemsPerPage(64)">64</b-dropdown-item>
          <b-dropdown-item @click="setItemsPerPage(128)">128</b-dropdown-item>
        </b-dropdown>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { apiRoute } from './../../../../helpers/api-route'
import { IOVThesholds } from './../../../../helpers/iovthresholds'

export default {
  name: 'RunAI',
  props: {
    project: {}
  },
  data () {
    return {
      selectedImage: null,
      bottomSheetOpen: false,
      aiImages: [],
      displayImages: [],
      displayImagesCache: [],
      dateFrom: '',
      dateTo: '',
      aiSubmission: [],
      memberData: [],
      fetchMem: false,
      fetchImg: false,
      tabIndex: 0,
      batchData: [],
      newData: 0,
      selectedImages: {},
      submitting: false,
      search: '',
      filterChoice: '',
      pageNumber: 0,
      itemsPerPage: 16,
      filterChoicePath: '',
      filterChoicePathArray: {},
      filterChoicePathArray2: []
    }
  },
  methods: {
    ...mapActions('alert', ['success']),
    ...mapActions('labelledImagesSubmission', ['fetchLabelledImages2', 'fetchAILabelledImages', 'downloadLabels', 'getBatches']),
    ...mapActions('projectSubmission', {
      submitImagesToAi: 'submitProjectImages'
    }),
    ...mapActions('usersSubmission', ['getMembers']),
    setItemsPerPage (x) {
      this.itemsPerPage = x
    },
    setPage (x) {
      this.pageNumber = x
    },
    nextPage () {
      this.pageNumber++
    },
    prevPage () {
      this.pageNumber--
    },
    imageAnalyzed (image) {
      // // console.log('image', image)
      if (image.details && image.details.otherImages && image.details.otherImages.length > 0) {
        if (this.aiImages.find(_image => _image.details.image_set.name === image.details.image_set.name)) {
          return true
        }
      } else {
        if (this.aiImages.find(_image => _image.id === image.id)) {
          return true
        }
      }
      return false
      // var op = this.aiImages.find(_image => _image.id === id)
    },
    refreshBatches () {
      this.getBatches()
    },
    selectAll () {
      this.reducedImages.filter(_image => !(_image.details.ai_diagnoses && _image.details.ai_diagnoses.size > 0 && !(_image.details.ai_diagnoses.has('Error') || _image.details.ai_diagnoses.has('Unknown')))).forEach(_image => {
        this.$set(this.selectedImages, _image.id, true)
      })
      // this.displayImages.filter(_image => !(_image.details.aiIov && _image.details.aiIov >= 0)).forEach(_image => {
      //   this.$set(this.selectedImages, _image.id, true)
      // })
    },
    async submitToAI () {
      try {
        const _params = {
          projectId: this.project._id,
          payload: {
            images: Object.keys(this.selectedImages).filter(
              (imageId) => this.selectedImages[imageId]
            )
          }
        }
        var others = []
        _params.payload.images.forEach(imgId => {
          var imgobj = this.displayImages.find(_img => _img.id === imgId)
          if (imgobj.details && imgobj.details.otherImages && imgobj.details.otherImages.length > 0) {
            imgobj.details.otherImages.forEach(__img => {
              others.push(__img.image._id)
            })
          }
        })
        if (others.length > 0) {
          _params.payload.images = _params.payload.images.concat(others)
        }
        this.submitting = true
        // console.log(_params)
        await this.submitImagesToAi(_params)
        this.success('Images submitted for AI processing.')
        this.fetchAILabelledImages(this.project._id)
        this.selectedImages = {}
        this.submitting = false
      } catch (error) {
        // // console.log(error)
      }
    },
    openInNewTab (url) {
      // // console.log(url)
      window.open(url)
    },
    downloadStop (status) {
      if (status === 'Completed') {
        return false
      } else {
        return true
      }
    },
    downloadImages () {
      var payload = {
        dateStart: this.dateFrom === '' ? new Date('2009-12-31') : this.dateFrom,
        dateEnd: this.dateTo === '' ? new Date() : this.dateTo,
        projectID: this.project._id
      }
      this.downloadLabels(payload)
    },
    resetDates () {
      this.dateFrom = ''
      this.dateTo = ''
    },
    transformAIImageData () {
      const data = this.images.aiImages
      if (!Array.isArray(data)) {
        return []
      }

      const transformedData = []

      // // console.log('ai data', this.readers)
      for (const item of data) {
        if (item.image && item.image.image && item.image.image.path) {
          transformedData.push({
            id: item.image._id,
            url: apiRoute() + '/image/' + item.image._id,
            styleFlag: 'white',
            details: { created: item.image.created_at, format: item.image.image.contentType, imgName: item.image.image.name, meta: item.image.metadata, image_set: item.image.image_set && item.image.image_set.name ? item.image.image_set : null, otherImages: item.otherImages }
          })
        }
      }
      // // console.log(transformedData)
      return transformedData
    },
    transformImageData () {
      var searchData = this.images.labelledImages.filter(img => img.image.image.name.includes(this.search))
      const data = searchData
      if (!Array.isArray(data)) {
        return []
      }

      var transformedData = []
      var modFlag
      var acc
      transformedData = []
      for (const item of data) {
        if (item.image && item.image.image && item.image.image.path && item.users.length >= this.readers.length && !item.image.removed.find(p => p.project === this.project._id)) {
          modFlag = false
          item.users.forEach(us => {
            if (us.projectRole.find(pr => pr.project === this.project._id).role === 'Moderator') {
              modFlag = true
            }
          })
          acc = undefined
          if (item.image.accuracy.filter(a => a.project === this.project._id).length > 0) {
            acc = item.image.accuracy.filter(a => a.project === this.project._id).slice(-1)[0].value
          } else {
            acc = 0
          }
          var _acc = []
          if (item.otherImages && item.otherImages.length > 0 && item.image.image_set && item.image.image_set.name) {
            item.otherImages.forEach(_item => {
              const _accuracy = _item.image.accuracy.filter(a => a.project === this.project._id)
              if (_accuracy && _accuracy.length > 0) {
                _acc.push(_accuracy.slice(-1)[0].value)
              }
            })
          }
          // console.log('acc', acc)
          _acc.push(acc)
          // console.log('all acc', _acc)
          _acc = _acc.filter(a => a !== 0)
          if (_acc.length > 0) {
            // console.log('av  acc', _acc.reduce((a, b) => a + b) / _acc.length)
            acc = _acc.reduce((a, b) => a + b) / _acc.length
          } else {
            acc = 0
          }

          if (acc >= IOVThesholds().fullAgreement && !modFlag) {
            this.aiSubmission.push(item)
          }
          var _pathologies = []
          item.pathologies.forEach(sub => {
            var keys = Object.keys(sub)
            keys.forEach(_k => {
              if (_k !== 'created_by') {
                _pathologies.push(sub[_k].name)
              }
            })
          })
          if (item.otherImages && item.otherImages.length > 0 && item.image.image_set && item.image.image_set.name) {
            item.otherImages.forEach(_item => {
              _item.pathologies.forEach(sub => {
                var keys = Object.keys(sub)
                keys.forEach(_k => {
                  if (_k !== 'created_by') {
                    _pathologies.push(sub[_k].name)
                  }
                })
              })
            })
          }
          var _tags = []
          // console.log(item)
          item.tags.forEach(sub => {
            var keys = Object.keys(sub)
            keys.forEach(_k => {
              if (_k !== 'created_by') {
                _tags.push(sub[_k])
              }
            })
          })
          if (item.otherImages && item.otherImages.length > 0 && item.image.image_set && item.image.image_set.name) {
            item.otherImages.forEach(_item => {
              _item.tags.forEach(sub => {
                var keys = Object.keys(sub)
                keys.forEach(_k => {
                  if (_k !== 'created_by') {
                    _tags.push(sub[_k])
                  }
                })
              })
            })
          }
          var _diagnoses = []
          // console.log(item)
          item.diagnoses.forEach(sub => {
            var keys = Object.keys(sub)
            keys.forEach(_k => {
              if (_k !== 'created_by') {
                // console.log('_k ', _k)
                // console.log('_k ', sub[_k])
                _diagnoses.push(sub[_k])
              }
            })
          })
          if (item.otherImages && item.otherImages.length > 0 && item.image.image_set && item.image.image_set.name) {
            item.otherImages.forEach(_item => {
              _item.diagnoses.forEach(sub => {
                var keys = Object.keys(sub)
                keys.forEach(_k => {
                  if (_k !== 'created_by') {
                    _diagnoses.push(sub[_k])
                  }
                })
              })
            })
          }
          var aiImg = this.images.aiImages.find(i => item.image._id === i.image._id)
          var aiDiagnoses = []
          if (aiImg) {
            aiImg.pathologies.forEach(p => {
              Object.keys(p).forEach(pkey => {
                if (pkey !== 'created_by') {
                  _pathologies.push(p[pkey].name)
                  // console.log('pkay', p[pkey].name)
                }
              })
            })

            if (aiImg.otherImages && aiImg.otherImages.length > 0 && aiImg.image.image_set && aiImg.image.image_set.name) {
              aiImg.otherImages.forEach(_item => {
                _item.pathologies.forEach(sub => {
                  var keys = Object.keys(sub)
                  keys.forEach(_k => {
                    if (_k !== 'created_by') {
                      _pathologies.push(sub[_k].name)
                    }
                  })
                })
              })
            }
            aiImg.diagnoses.forEach(p => {
              Object.keys(p).forEach(pkey => {
                if (pkey !== 'created_by') {
                  aiDiagnoses.push(p[pkey])
                  // console.log('pkay', p[pkey].name)
                }
              })
            })

            if (aiImg.otherImages && aiImg.otherImages.length > 0 && aiImg.image.image_set && aiImg.image.image_set.name) {
              aiImg.otherImages.forEach(_item => {
                _item.diagnoses.forEach(sub => {
                  var keys = Object.keys(sub)
                  keys.forEach(_k => {
                    if (_k !== 'created_by') {
                      aiDiagnoses.push(sub[_k])
                    }
                  })
                })
              })
            }
          }
          transformedData.push({
            id: item.image._id,
            url: apiRoute() + '/image/' + item.image._id,
            styleFlag: modFlag ? 'purple' : acc >= IOVThesholds().fullAgreement && acc <= IOVThesholds().partialAgreement ? 'orange' : acc > IOVThesholds().partialAgreement ? 'red' : 'white',
            details: {
              created: item.image.created_at,
              format: item.image.image.contentType,
              imgName: item.image.image.name,
              meta: item.image.metadata,
              iou: acc,
              aiIov: item.image.ai_accuracy.filter(a => a.project === this.project._id).length > 0 ? item.image.ai_accuracy.filter(a => a.project === this.project._id).slice(-1)[0].value : null,
              pathologies: new Set(_pathologies),
              ai_diagnoses: new Set(aiDiagnoses),
              tags: new Set(_tags),
              diagnoses: new Set(_diagnoses),
              image_set: item.image.image_set && item.image.image_set.name ? item.image.image_set : null,
              otherImages: item.otherImages
            }
          })
        }
      }
      // // console.log(transformedData)
      return transformedData
    },
    openBottomSheet (image) {
      if (this.selectedImage === image) {
        // Deselect the image if it's already selected
        this.selectedImage = null
      } else {
        // Select the image
        this.selectedImage = image
      }
      image.analyzed = !!this.imageAnalyzed(image)
      this.$emit('open-bottom-sheet', image)
    },
    hasPath (img, filter) {
      var temp = false
      if (this.filterChoicePathArray2.length === 0) {
        temp = true
      }
      Object.keys(filter).forEach(_ch => {
        if (_ch === 'Other' && this.filterChoicePathArray[_ch]) {
          img.details.pathologies.forEach(v => {
            if (!this.project.pathologies.find(p => p.label === v)) {
              temp = true
            }
          })
          // // console.log(img.details.pathologies.values())
          // // console.log(this.project.pathologies.filter(p => !img.details.pathologies.has(p)).length < 1) && this.project.pathologies.filter(p => !img.details.pathologies.has(p)).length < 1
          // && this.project.pathologies.filter(p => img.details.pathologies.has(p))) {p => !this.project.pathologies.includes(p)
        } else if (_ch !== 'Other' && this.filterChoicePathArray[_ch] && img.details.pathologies.has(_ch)) {
          temp = true
        }
      })
      return temp
    },
    hasGroup (img, val) {
      var _filter = this.project.subgroups.find(sg => sg.name === val).definiation
      // console.log(_filter)
      var includeInFilter = false
      Object.keys(_filter).forEach(_key => {
        if (_key === 'Pathologies') {
          _filter[_key].forEach(path => {
            if (img.details.pathologies.has(path)) {
              includeInFilter = true
            }
          })
        } else if (_key === 'Tags') {
          _filter[_key].forEach(tag => {
            // console.log(img.details)
            if (img.details.tags.has(tag)) {
              includeInFilter = true
            }
          })
        } else if (_key === 'Diagnosis') {
          _filter[_key].forEach(dia => {
            if (img.details.diagnoses.has(dia)) {
              includeInFilter = true
            }
          })
        } else if ((img.details.meta && img.details.meta[0] && img.details.meta[0] && img.details.meta[0].values.find(_meta => _meta.name === _key) && _filter[_key].includes(img.details.meta[0].values.find(_meta => _meta.name === _key).value))) {
          includeInFilter = true
        }
      })
      // // console.log('sg', inc ludeInFilter)
      return includeInFilter
    },
    inSearch  (img, val) {
      if (img.details.image_set && img.details.image_set.name) {
        return img.details.image_set.name.includes(val) || img.details.image_set.sequence.includes(val)
      } else {
        return img.details.imgName.includes(val)
      }
    },
    addPath (val) {
      if (this.filterChoicePathArray2.includes(val)) {
        this.filterChoicePathArray2 = this.filterChoicePathArray2.filter(p => p !== val)
      } else {
        this.filterChoicePathArray2.push(val)
      }
    },
    compareLabel (a, b) {
      return a.label.localeCompare(b.label)
    }
  },
  computed: {
    ...mapState('labelledImagesSubmission', {
      images: (state) => state
    }),
    ...mapState('usersSubmission', { members: (state) => state }),
    aiImagesLoaded () {
      return this.images.aiLoaded
    },
    pathList () {
      var paths = this.project.pathologies
      var _paths = paths.sort(this.compareLabel)
      return _paths
    },
    reducedImages () {
      this.filterChoicePathArray2.forEach(_key => {
        // console.log('key', _key)
      })
      var _images = this.displayImages
      if (this.filterChoice !== 'All' && this.filterChoice !== '') {
        _images = _images.filter(img => this.hasGroup(img, this.filterChoice))
      }
      if (this.search !== '') {
        _images = _images.filter(img => this.inSearch(img, this.search))
      }
      return _images
    },
    paginatedData () {
      const start = this.pageNumber * this.itemsPerPage
      const end = start + this.itemsPerPage
      return this.reducedImages.slice(start, end)
    },
    maxPages () {
      return (this.reducedImages.length / this.itemsPerPage >> 0) + 1
    },
    imagesLoaded () {
      return this.images.loaded
    },
    downloading () {
      return this.images.downloading
    },
    loadingBatches () {
      return this.images.bacthLoading
    },
    selectedImageCount () {
      return Object.values(this.selectedImages).filter(Boolean).length
    },
    allProcessed () {
      return this.displayImages.length === this.aiImages.length
    },
    readers () {
      if (this.members && this.members.members && this.members.members.length > 0 && this.project) {
        const _project = this.project._id
        const _readers = []
        this.members.members.forEach(_member => {
          const _role = _member.projectRole.find(_role => _role.project === _project)
          if (_role && _role.role === 'Reader') {
            _readers.push(_member)
          }
        })

        return _readers
      } else {
        return []
      }
    },
    moderators () {
      if (this.members && this.members.members && this.members.members.length > 0 && this.project) {
        const _project = this.project._id
        const _readers = []
        this.members.members.forEach(_member => {
          const _role = _member.projectRole.find(_role => _role.project === _project)
          if (_role && _role.role === 'Moderator') {
            _readers.push(_member)
          }
        })

        return _readers
      } else {
        return []
      }
    },
    filterChange () {
      return this.filterChoicePathArray2
    }
  },
  watch: {
    filterChange (_new) {
      // console.log('change', _new)
    },
    aiImagesLoaded (_new) {
      if (_new) {
        this.aiImages = this.transformAIImageData()
      }
    },
    imagesLoaded (_new) {
      if (_new) {
        this.displayImages = this.transformImageData(false)
        this.displayImagesCache = this.displayImages
      }
    },
    downloading (_new) {
      if (!_new) {
        this.getBatches()
        this.newData++
      }
    },
    loadingBatches (_new) {
      // // console.log('new batch')
      if (!_new) {
        this.newData++
      }
    }
  },
  mounted () {
    this.getBatches()
    this.displayImages = this.transformImageData(false)
    this.displayImagesCache = this.displayImages
    this.aiImages = this.transformAIImageData()
    this.project.pathologies.forEach(_p => {
      this.filterChoicePathArray[_p.label] = false
    })
  }
}
</script>

<style scoped>
.annotation-images-row {
  display: flex;
  flex-wrap: wrap;
}

.batchTable {
  border-top: 1px solid #573f0880;
  padding-top: 10px;
  margin-bottom: 5px;

}

.annotation-image {
  position: relative;
  margin: 3%;
  cursor: pointer;
}

.selected {
  border: 1px solid #f0b93b;
}

.img-fluid {
  border-radius: 10px;
  border: 2px solid white;
}

.empty-images {
  color: #ccc;
  margin-top: 50px;
}

.image-checkbox {
  position: absolute;
  opacity: 1;
  width: 20px;
  height: 20px;
  top: -12px;
  left: -12px;
  cursor: pointer;
}

.uploaded-images {
  border-radius: 10px;
}

.image-container {
  position: relative;
  margin: 10px;
  cursor: pointer; /* Add cursor pointer for clickable effect */
}

.remove-button {
  position: absolute;
  top: 8px;
  right: 0px;
  background: none;
  border: none;
  color: red;
  cursor: pointer;
}

.remove-button i {
  font-size: 18px;
}

/* Style the images */
.img-fluid {
  border-radius: 10px;
  border: 0.5px solid white;
}

.indicator {
  font-size: 10px;
  padding: 4px 8px;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
}

.trained-green {
  background-color: green;
}

.trained-red {
  background-color: red;
}
.trained-blue {
  background-color: #14304ced;
}
</style>
<style scoped>
  .my-class /deep/ .dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
  }
  .indicator-set {
  font-size: 10px;
  padding: 4px 8px;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 35px;
  left: 5px;
}
</style>
