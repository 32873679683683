<template>
  <div class="image-viewer-modal">
    <!-- Modal Content -->
    <div class="row">
      <!-- Left Column: Display Image -->
      <div class="col-md-6">
        <div class="image-container">
          <img
            :src="getRoute+'/image/' +image._id"
            alt="Image"
            class="img-fluid image-border"
          />
        </div>
      </div>
      <!-- Right Column: Display Data -->
      <div class="col-md-6">
        <h4 class="text-white">Image Data</h4>
        <ul class="list-group list-unstyled">
          <li class="list-group-item p-0">
            <i class="fa fa-file-image text-primary mr-2"></i>
            <strong class="text-white">Name:</strong>
            <i class="fa fa-file-text-o text-primary mr-2"></i>
            {{ image.image.name }}
          </li>
          <li class="list-group-item p-0">
            <i class="fa fa-calendar text-primary mr-2"></i>
            <strong class="text-white">Uploaded At:</strong>
            <i class="fa fa-clock-o text-primary mr-2"></i>
            {{ formatDate(image.created_at) }}
          </li>
          <li class="list-group-item p-0">
            <i class="fa fa-check-circle text-primary mr-2"></i>
            <strong class="text-white">Trained:</strong>
            {{ image.trained ? "Yes" : "No" }}
          </li>
          <li class="list-group-item p-0">
            <i class="fa fa-check-square text-primary mr-2"></i>
            <strong class="text-white">Tested:</strong>
            {{ image.tested ? "Yes" : "No" }}
          </li>
          <li class="list-group-item p-0">
            <i class="fa fa-file text-primary mr-2"></i>
            <strong class="text-white">Size:</strong>
            {{ formatSize(image.image.size) }} MB
          </li>
        </ul>
        <div class="mt-4 text-right">
          <button
            class="btn btn-primary rounded-pill"
            @click="assignImage"
            :disabled="image.projects.includes(projectId._id)"
          >
            <i class="fa fa-plus-circle mr-2"></i>
            Assign Image
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDistanceToNow } from 'date-fns'
import { apiRoute } from '../../../../helpers'
import { mapActions } from 'vuex'

export default {
  props: {
    image: Object,
    projectId: Object
  },
  data () {
    return {
      showModal: false // Set to false initially
    }
  },
  computed: {
    getRoute () {
      return apiRoute()
    }
  },
  methods: {
    ...mapActions('projectSubmission', {
      assignImageToProject: 'assignImageToProject'
    }),
    closeModal () {
      this.$emit('closeModalChild')
    },
    openModal () {
      this.showModal = true
    },
    formatSize (size) {
      // Convert bytes to megabytes
      const megabytes = size / (1024 * 1024)
      return megabytes.toFixed(2) // Display size with two decimal places
    },
    formatDate (date) {
      // Format date as "time ago"
      return formatDistanceToNow(new Date(date), { addSuffix: true })
    },
    async assignImage () {
      var selectedImageIds = []
      selectedImageIds.push(this.image._id)
      try {
        this.$emit('assiningSingle')
        await this.assignImageToProject({
          projectId: this.projectId._id,
          imageIds: selectedImageIds
        }).then(this.$emit('closeModalChild'))
      } catch (error) {
        console.error('Error assigning images to project:', error)
      }
    }
  },
  watch: {
    image: {
      handler () {
        // When the image prop changes, open the modal
        this.openModal()
      },
      deep: true
    }
  }
}
</script>

<style scoped>
/* Add CSS styles for the modal */
.image-viewer-modal .modal-content {
  background-color: #14304c;
  color: white;
}

.image-container {
  border: 2px solid #14304c;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.image-border {
  border-radius: 5px;
}

.list-group-item {
  padding: 12px 10px; /* Increase vertical padding and keep horizontal padding */
  background-color: transparent; /* Set background to transparent */
  margin: 10px 0; /* Increase margin between list items */
  font-size: 16px; /* Increase font size */
}

.bg-yellow {
  background-color: #f1c40f;
  color: white;
}
</style>
