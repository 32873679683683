import { apiRoute, authHeader, handleResponse } from '../helpers'

export const userService = {
  users,
  getMembers,
  deleteUser,
  updateUserProfile,
  addMember,
  getMembersNotInProject
}

function users () {
  return fetch(`${apiRoute()}/api/v1/admin/users/`, {
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  }).then(handleResponse)
}
function getMembersNotInProject (projectId) {
  return fetch(`${apiRoute()}/api/v1/admin/users/projects/${projectId}/users-not-in-project`, {
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  }).then(handleResponse)
}
function getMembers (projectId) {
  return fetch(`${apiRoute()}/api/v1/admin/users/${projectId}/members/`, {
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  }).then(handleResponse)
}

function updateUserProfile (userInfo) {
  return fetch(`${apiRoute()}/api/v1/admin/users/projects/${userInfo._id}`, {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(userInfo)
  }).then(handleResponse)
}

function deleteUser (projectId, userId) {
  return fetch(
    `${apiRoute()}/api/v1/admin/users/projects/${projectId}/members/${userId}/`,
    {
      method: 'DELETE',
      headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
  ).then(handleResponse)
}

function addMember (projectId, membersWithRoles) {
  // console.log('this is the data of the members', membersWithRoles)
  return fetch(
    `${apiRoute()}/api/v1/training/images/projects/${projectId}/add/members/`,
    {
      method: 'PUT',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify({
        members: membersWithRoles // Use the correct property name here
      })
    }
  ).then(handleResponse)
}
