import { staffSubmissionService } from '../services'
import { apiRoute } from '../helpers'

const state = {
  info: {},
  status: {
    loading: false,
    loaded: false,
    notFound: false,
    saving: false,
    saved: false,
    progress: 0
  }
}

const actions = {
  get ({ dispatch, commit }, id) {
    commit('getSubmission', id)

    staffSubmissionService.get(id)
      .then(
        submission => {
          if (!submission) {
            commit('getSubmissionNotFound')
          } else {
            if (submission.attachments && submission.attachments.length > 0) {
              submission.attachments.forEach((record, index) => {
                record.localUrl = apiRoute() + '/image/submission/' + submission._id + '/' + index
              })
            }

            commit('getSubmissionSuccess', { qualifier: id, submissions: submission })
          }

          dispatch('alert/clear', null, { root: true })
        },
        error => {
          commit('getSubmissionFailure')
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  getByIdentifiers ({ dispatch, commit }, params) {
    commit('getSubmission', params)

    staffSubmissionService.getByIdentifiers(params)
      .then(
        submission => {
          if (!submission) {
            commit('getSubmissionNotFound')
          } else {
            if (submission.attachments && submission.attachments.length > 0) {
              submission.attachments.forEach((record, index) => {
                record.localUrl = apiRoute() + '/image/submission/' + submission._id + '/' + index
              })
            }

            commit('getSubmissionSuccess', { qualifier: params.patient, submissions: submission })
          }

          dispatch('alert/clear', null, { root: true })
        },
        error => {
          commit('getSubmissionFailure')
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  getAll ({ dispatch, commit }, filter) {
    commit('getAllSubmissions', filter)

    staffSubmissionService.getAll(filter)
      .then(
        submissions => {
          if (!submissions) {
            commit('getAllSubmissionsNotFound')
          } else {
            commit('getAllSubmissionsSuccess', { qualifier: 'all', submissions: submissions })
          }

          dispatch('alert/clear', null, { root: true })
        },
        error => {
          commit('getAllSubmissionsFailure')
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  getCovid ({ dispatch, commit }, period) {
    commit('getAllSubmissions', period)

    staffSubmissionService.getCovid(period)
      .then(
        submissions => {
          if (!submissions) {
            commit('getAllSubmissionsNotFound')
          } else {
            commit('getAllSubmissionsSuccess', { qualifier: period, submissions: submissions })
          }

          dispatch('alert/clear', null, { root: true })
        },
        error => {
          commit('getAllSubmissionsFailure')
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  clear ({ commit }) {
    commit('clearSubmission')
  }
}

const mutations = {
  getSubmission (state, id) {
    state.info = {
      _id: id
    }
    state.status = {
      loading: true,
      loaded: false,
      notFound: false,
      saving: false,
      saved: false,
      progress: 0
    }
  },
  getSubmissionNotFound (state) {
    state.info = null
    state.status = {
      loading: false,
      loaded: false,
      notFound: true,
      saving: false,
      saved: false,
      progress: 0
    }
  },
  getSubmissionFailure (state) {
    state.info = null
    state.status = {
      loading: false,
      loaded: true,
      notFound: false,
      saving: false,
      saved: false,
      progress: 0
    }
  },
  getSubmissionSuccess (state, data) {
    state.info[data.qualifier] = data.submissions
    state.status = {
      loading: false,
      loaded: true,
      notFound: false,
      saving: false,
      saved: false,
      progress: 0
    }
  },
  updateSubmission (state) {
    state.status = {
      loading: false,
      loaded: false,
      notFound: false,
      saving: true,
      saved: false,
      progress: 0
    }
  },
  updateSubmissionSuccess (state) {
    state.status = {
      loading: false,
      loaded: false,
      notFound: false,
      saving: false,
      saved: true,
      progress: 0
    }
  },
  updateSubmissionFailure (state) {
    state.status = {
      loading: false,
      loaded: false,
      notFound: false,
      saving: false,
      saved: true,
      progress: 0
    }
  },
  getAllSubmissions (state, filter) {
    state.info = {
      filter: filter
    }
    state.status = {
      loading: true,
      loaded: false,
      notFound: false,
      saving: false,
      saved: false,
      progress: 0
    }
  },
  getAllSubmissionsNotFound (state) {
    state.info = null
    state.status = {
      loading: false,
      loaded: true,
      notFound: false,
      saving: false,
      saved: false,
      progress: 0
    }
  },
  getAllSubmissionsSuccess (state, data) {
    state.info[data.qualifier] = data.submissions
    state.status = {
      loading: false,
      loaded: true,
      notFound: false,
      saving: false,
      saved: false,
      progress: 0
    }
  },
  getAllSubmissionsFailure (state) {
    state.info = null
    state.status = {
      loading: false,
      loaded: true,
      notFound: false,
      saving: false,
      saved: false,
      progress: 0
    }
  },
  clearSubmission (state) {
    state.info = null
    state.status = {
      loading: false,
      loaded: false,
      notFound: false,
      saving: false,
      saved: false,
      progress: 100
    }
  }
}

export const staffSubmission = {
  namespaced: true,
  state,
  actions,
  mutations
}
