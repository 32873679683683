<template>
  <b-card no-body class="h-100 bg-dark-navy custom border-0">
    <template v-slot:header>
      <b-row class=" mb-0" >
        <b-col cols="8" >
          <font-awesome-icon :icon="getIcon()" size="lg" class="mr-1"></font-awesome-icon> {{ getPhase() }}
        </b-col>
        <b-col  cols="3" align-self="center" class="text-right">
          <h6 class="color-white pt-0 mb-0">{{ projects.find((project) => project._id === projectId).name }}</h6>
        </b-col>
        <b-col cols="1" align-self="center" class="text-right">
          <b-button class="btn px-3 btn-sm btn-yellow rounded-pill" @click="refreashDetails()"> <font-awesome-icon icon="sync"  /> </b-button>
        </b-col>
      </b-row>
    </template>
    <b-card-body class="p-0" :key="selected">
      <div class="gallery-content" :style="{height: bottemSheetState.isOpen ? '35vh' : '100vh' }">
      <members :projectId="projectId" v-if="selected === 9"></members>
      <project-details
        v-if="selected === 10"
        :project="this.currentProject"
        @phase-select = "changePhase"
      ></project-details>
      <project-set-up
        v-if="selected === 5"
        :project="this.currentProject"
      ></project-set-up>
      <data-uploader
        v-if="selected === 1"
        :project="this.currentProject"
        @open-bottom-sheet="openBottomSheet"
      ></data-uploader>
      <annotations
        v-if="selected == 3  && proMembers.length>0"
        :projectId="this.currentProject"
        @open-bottom-sheet="openBottomSheet"
      ></annotations>
      <ground-truth
        v-if="selected == 4 && proMembers.length>0"
        :project="this.currentProject"
        @open-bottom-sheet="openBottomSheet"
      ></ground-truth>
      <run-ai
        v-if="selected == 6"
        :project="this.currentProject"
        @open-bottom-sheet="openBottomSheet"
      ></run-ai>
      <detailed-results
        v-if="selected == 7"
        :project="this.currentProject"
        @open-bottom-sheet="openBottomSheet"
      ></detailed-results>
      <summary-results
        v-if="selected == 8"
        :project="this.currentProject"
      ></summary-results>
      <ai-model-training
        v-if="selected === 12"
        :project="this.currentProject"
      ></ai-model-training>
    </div>
    </b-card-body>
    <template v-slot:footer>
      <b-card-footer class="scrollable" v-if="bottemSheetState.isOpen">
        <b-row class="bsheet mr-3 mt-1">
          <b-button class=" btn btn-yellow rounded-pill" @click="toggleImageDetails()">
              <font-awesome-icon class="fa color-navy"  icon="times"  />
            </b-button>
        </b-row>
        <div :style="{height: bottemSheetState.isOpen ? '65vh' : '35vh' }">
          <div class="text-center my-4" v-if="bottemSheetState.loading">
            <b-spinner scale="4" class="color-yellow"></b-spinner>
            <div>Loading Image...</div>
          </div>
          <BottomSheet class="mt-2"
            v-if="!bottemSheetState.loading"
            @closeBottom = toggleImageDetails
          />
        </div>
      </b-card-footer>
      <b-card-footer class="mb-2 pb-1" v-else>
        <b-row class="bsheet">
          <font-awesome-icon icon="chevron-up" size="2x" />
        </b-row>
      </b-card-footer>
    </template>
  </b-card>
</template>

<script>
import DataUploader from './DataCollection/DataUploader.vue'
import Members from './Members/Members.vue'
import ProjectDetails from './ProjectDetails/ProjectDetails.vue'
import ProjectSetUp from './ProjectSetUp/ProjectSetUp.vue'
import 'cropperjs/dist/cropper.css'
import Annotations from './Annotations/Annotations.vue'
import { mapState, mapActions } from 'vuex'
import BottomSheet from './Annotations/BottomSheet.vue'
import GroundTruth from './GroundTruth/GroundTruth.vue'
import RunAi from './AI/Run.vue'
import DetailedResults from './Results/Detailed'
import SummaryResults from './Results/Summary'
import AiModelTraining from './Training/AiModelTraining.vue'

export default {
  components: {
    Members,
    ProjectDetails,
    ProjectSetUp,
    DataUploader,
    Annotations,
    BottomSheet,
    GroundTruth,
    RunAi,
    DetailedResults,
    SummaryResults,
    AiModelTraining
  },
  data () {
    return {
      phases: {
        retrospective: [
          { id: 10, stub: 'setup', name: 'Project Details', icon: 'sitemap' },
          { id: 5, stub: 'setup', name: 'Project set-up', icon: 'cogs' },
          { id: 1, stub: 'collection', name: 'Data collection', icon: 'photo-video' },
          // { id: 2, stub: 'preprocessing', name: 'Data pre-processing', icon: 'clone' },
          { id: 3, stub: 'annotation', name: 'Data annotation', icon: 'crop' },
          { id: 4, stub: 'truth', name: 'Reference standard', icon: 'check-double' },
          { id: 12, stub: 'training', name: 'AI Model Training', icon: 'users-cog' },
          { id: 6, stub: 'run', name: 'AI Processing', icon: 'hourglass-start' },
          { id: 7, stub: 'results', name: 'Detailed results', icon: 'clipboard-list' },
          { id: 8, stub: 'summary', name: 'Results summary', icon: 'chart-area' },
          { id: 9, stub: 'members', name: 'Members', icon: 'users-cog' }],
        prospective: [
          { id: 5, stub: 'setup', name: 'Project set-up', icon: 'cogs' },
          { id: 1, stub: 'collection', name: 'Data collection', icon: 'photo-video' },
          { id: 10, stub: 'feedback', name: 'Inference feedback', icon: 'crop' },
          { id: 11, stub: 'moderation', name: 'Feedback moderation', icon: 'check-double' },
          { id: 7, stub: 'results', name: 'Detailed results', icon: 'clipboard-list' },
          { id: 8, stub: 'summary', name: 'Results summary', icon: 'chart-area' },
          { id: 9, stub: 'members', name: 'Members', icon: 'users-cog' }
        ]
      },
      brightness: 100,
      contrast: 100,
      saturate: 100,
      sepia: 0,
      invert: 0,
      hue: 0,
      active: null,
      files: [],
      accept: 'image/png,image/jpeg',
      extensions: 'jpg,jpeg,png',
      uploadAuto: false,
      editFile: {
        show: false,
        name: ''
      },
      uploadIn: 60,
      noOfFiles: 0,
      headers: {
        Authorization: 'Bearer '
      },
      data: {
        _hash: null,
        user: this.user ? this.user.email : ''
      },
      annotationFooterVisable: false,
      selectedUser: null,
      selectedImage: [],
      annotationLoading: true,
      proMembers: [],
      rejectPayload: {}
    }
  },
  created () {},
  mounted () {
    // get mem this.projectId
    this.getMembers(this.projectId)
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    selected: {
      type: Number,
      default: null
    },
    projectId: {
      type: String,
      default: null
    }
  },
  watch: {
    loadMem (_new) {
      if (!_new) {
        this.proMembers = this.users.members
      }
    },
    selectionChanged (_new) {
      this.bsclear()
    }
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      projects: (state) => state.projectSubmission.projects,
      userProjects: (state) => state.projectSubmission.projects,
      users: (state) => state.usersSubmission,
      bottemSheetState: (state) => state.BottomSheet
    }),
    ...mapState('labelledImagesSubmission', {
      images: (state) => state.labelledImages,
      imagesWatch: (state) => state.loading,
      imagesAi: (state) => state.aiImages
    }),
    ...mapState('BottomSheet', {
      bsWatch: (state) => state.isOpen
    }),
    selectionChanged () {
      return this.selected
    },
    loadMem () {
      return this.users.loading
    },
    getFooterHeight () {
      if (this.annotationFooterVisable) {
        // var h = window.innerHeight
        // console.log(h * 0.75)
        return { height: '1000px' }
      } else {
        return { visable: false }
      }
    },
    currentPhase () {
      return 1
    },
    currentProject () {
      const filteredProject = this.userProjects.find(
        (project) => project._id === this.projectId
      )
      // // // console.log('filtered project', filteredProject)
      return filteredProject
    },
    user () {
      if (this.auth && this.auth.status && this.auth.status.loggedIn) {
        return this.auth.user
      } else {
        return null
      }
    }
  },
  methods: {
    ...mapActions('BottomSheet', {
      toggleBS: 'toggleVisablity',
      getAnnotationsMap: 'getAnnotationsMap',
      bsclear: 'clear'
    }),
    ...mapActions('labelledImagesSubmission', ['fetchLabelledImages2', 'fetchAILabelledImages']),
    ...mapActions('usersSubmission', {
      getMembers: 'getMembers'
    }),
    ...mapActions('projectSubmission', ['getProjectImages2', 'getRejections2', 'getAllImages2']),
    refreashDetails () {
      console.log('refresh', this.projectId)
      this.getMembers(this.projectId)
      this.fetchLabelledImages2(this.projectId)
      // console.log('pul')
      this.fetchAILabelledImages(this.projectId)
      this.getProjectImages2(this.projectId)
      this.getRejections2(this.projectId)
      this.getAllImages2(this.projectId)
    },
    toggleImageDetails () {
      this.bsclear()
    },
    changePhase (i) {
      this.$emit('phase-selected', i)
    },
    async openBottomSheet (image) {
      try {
        this.toggleBS(true)
        // console.log('Final', image)
        this.rejectPayload = {
          projectId: this.projectId,
          imageID: image.id,
          phase: this.selected
        }
        var _payload = {}
        if (this.rejectPayload.phase === 7 || this.rejectPayload.phase === 6) {
          _payload = { labeledSubmissions: this.images, phaseData: this.rejectPayload, members: this.users.members, image: image, aiImages: this.imagesAi }
        } else {
          _payload = { labeledSubmissions: this.images, phaseData: this.rejectPayload, members: this.users.members, image: image }
        }
        this.getAnnotationsMap(_payload)
      } catch (error) {
        console.error('Error fetching labelled images:', error)
      }
    },
    getPhase: function () {
      if (this.projectId) {
        const _project = this.list.find(_project => _project._id === this.projectId)
        const _phase = this.phases[_project.type.toLowerCase()].find(_phase => _phase.id === this.selected)
        return _phase.name
      } else {
        return ''
      }
    },
    getIcon: function () {
      if (this.projectId) {
        const _project = this.list.find(_project => _project._id === this.projectId)
        // console.log('_phase', _project.type.toLowerCase())
        const _phase = this.phases[_project.type.toLowerCase()].find(_phase => _phase.id === this.selected)
        return _phase.icon
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped>

.bsheet {
  margin-bottom: 0px !important;
  justify-content: right;
  position: sticky;
  top: 0;
  z-index: 9999;
}
.upload-files-area .list-group .list-group-item {
  background-color: transparent;
  color: #9ca2a7;
  position: relative;
  border: none;
}
.upload-files-area .list-group .list-group-item .options {
  position: absolute;
  top: 0px;
  right: 5px;
}
.upload-files-area .list-group .list-group-item .options .dropdown-menu {
  background-color: transparent;
  min-width: auto !important;
  border-radius: 5px;
}
.upload-files-area .list-group .list-group-item .options .dropdown-menu:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #ffffff;
  bottom: 100%;
  right: 10px;
}
.upload-files-area
  .list-group
  .list-group-item
  .options
  .dropdown-menu
  .dropdown-item {
  background-color: transparent;
  padding: 0px;
}
.upload-files-area .list-group .list-group-item .options .border-right {
  border-right: 1px solid #cfd3d8 !important;
  margin-right: 1px;
}
.upload-files-area .list-group .list-group-item .options .btn-edit:hover {
  background-color: #007bff !important;
}
.upload-files-area .list-group .list-group-item .options .btn-delete:hover {
  background-color: #dc3545 !important;
}
.upload-files-area .list-group .list-group-item .options .btn-edit:hover > i,
.upload-files-area .list-group .list-group-item .options .btn-delete:hover > i {
  color: #ffffff !important;
}
.upload-files-area
  .list-group
  .list-group-item:not(.active):not(:last-child):after {
  content: "";
  height: 1px;
  position: absolute;
  left: 1.25rem;
  right: 1.25rem;
  bottom: 0px;
}
.upload-files-area .list-group .list-group-item:hover,
.upload-files-area
  .list-group
  .list-group-item:not(.active):not(:last-child):after {
  background-color: #14304c;
}
.upload-files-area .list-group .list-group-item.active {
  background-color: #0e243a;
}
.bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.width-80 {
  width: 80%;
  border: 1px solid #14304c;
}
.upload-files-area {
  border-radius: 1.25rem;
  border: 1px solid #14304c;
}
.upload-files-area:hover {
  background-color: #14304c33;
}
.line-height-1 {
  line-height: 1.2;
}
.modal-backdrop.fade {
  visibility: hidden;
}
.modal-backdrop.fade.show {
  visibility: visible;
}
.fade.show {
  display: block;
  z-index: 1072;
}
.modal-body {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

.scrollable {
  overflow-y: auto;
}

.gallery-content{
  /* height: 100vh; */
  /* height: 40vh */
}
</style>
